import { Injectable ,OnDestroy} from '@angular/core';

import * as CryptoJS from 'crypto-js';

//import { Http, Response, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { HttpClient, HttpResponse, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { AbstractControl ,FormBuilder} from '@angular/forms';
import * as xlsx from 'xlsx';
import * as XLSX from 'xlsx';
import { ClsResponse } from '../shared/cls-response.model'
import { ClsSession } from '../shared/cls-session.model'

import * as SIPF_STRING from '../global/common.model'
import { string, System } from '@amcharts/amcharts4/core';
import * as Rx from "rxjs/Rx";
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


declare const $: any;

@Injectable()
export class SingletonService implements OnDestroy {

  Menu = [];
  PageRow: any;
  INPUTTYPE: String;
  ClsSessionOBJ: ClsSession;
  encryptSecretKey: string = this.getToken();

  isSidebarVisible: boolean;
  ListPageSize: any;

  private timeoutId: any
  time: number = 0;
  interval: any;
  play: boolean = false;
  chkToken_sing: string;

  constructor(private http: HttpClient,private fb: FormBuilder) {
    this.isSidebarVisible = false;
    this.ListPageSize = [

      { "value": "50", "text": "50" },
      { "value": "100", "text": "100" },
      { "value": "500", "text": "500" },
      { "value": "10000000", "text": "ALL" }]
  }

  createAuthorizationHeader(headers: HttpHeaders) {


    // headers.append("Access-Control-Allow-Origin", "*");
    // headers.append("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS");
    // headers.append("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With");

    headers.append('token', this.getToken());
    headers.append('emp', this.getEMPID());
    headers.append('Content-Type', 'application/json');


  }

  isShowMenu(): boolean {
    return sessionStorage.getItem('show_menu') == "true" ? true : false
  }

  isShowSearch(): boolean {
    return sessionStorage.getItem('show_search') == "true" ? true : false
  }

  isLoggedIn(): boolean {
    const result = !!(sessionStorage.getItem('employee_id'));
    return result;
  }
  isTokenExists(): boolean {
    const result = !!(sessionStorage.getItem('token'));
    return result;
  }

  getToken(): string {
    return sessionStorage.getItem('token');
  }

  getfyyear(): string {

    return sessionStorage.getItem('Value');
  }

  get_KIOSKCODE(): string {
    return sessionStorage.getItem('KIOSKCODE');
  }
  getEMPID(): string {
    return sessionStorage.getItem('employee_id');
  }

  getDetails(): ClsSession {
    return JSON.parse(atob(sessionStorage.getItem('info'))); //Login page
    //return JSON.parse(sessionStorage.getItem('info')); // SSO login Page
  }

  getLastApplicationUrl(): string {
    return sessionStorage.getItem('LastApplicationUrl');
  }


  getMenusDetails(): string {
    return sessionStorage.getItem('Menus');
  }


  isAuthorizationForPage(pageUrl: string): boolean {
    
    if (this.getCurrentRoleName() != "EMPLOYEE") {

      if (JSON.parse(sessionStorage.getItem('Menus')) == null) {
        
        this.postRequest(null, 'MenuRole/RoleBaseMenu?roleid=' + this.getCurrentRole(), 'SELECT').subscribe(
          x => {
            
            if (x.success) {
              if (x.data == null) {
                $.AdminOreo.alert.message_error("Record Not Found.", "");
              }
              else {
                this.Menu = x.data.ListMenuItem;
              }
            }
            else {
            }
            err => {
              $.AdminOreo.loader.unblockUI();
              // Log errors if any
              console.log(err);
            }
          });
      }
      else {
        this.Menu = JSON.parse(sessionStorage.getItem('Menus'));
      }
      
      this.PageRow = this.Menu.filter(item => item.PAGE_URL == pageUrl || item.PAGE_DETAIL_URL == pageUrl);

      if (pageUrl == sessionStorage.getItem('current-role-dashboard') || pageUrl == "employee/dashboard") {
        return true;
      }
      else {
        return this.PageRow.length > 0 ? true : false
      }
    }
    else {
      if (pageUrl == "employee/dashboard") {
        return true;
      }
      else {
        return false
      }
    }
  }


  UnAuthorizationAccess = function () {
    sessionStorage.clear();
    $(location.href = '/authentication/authorization');
 

    // $.AdminOreo.alert.message_error("", "Unauthorization Access Found.", function () {

    // });

    
  }

  OtherEMPDETAIL(ID: String) {
    this.postRequest(null, 'Auth/OtherEMP_PROFILE_DETAIL/' + ID, 'LIST').subscribe(
      x => {
        
        if (x.success) {
          this.ClsSessionOBJ = x.data;
        }
        else {
          this.ClsSessionOBJ = x.data;
        }
      });

    return this.ClsSessionOBJ;
  }

  getDetailsSearch(): ClsSession {
    return JSON.parse(localStorage.getItem('searchinfo'));
  }


  getRoles() {

    return JSON.parse(sessionStorage.getItem('roles'));
  }

  getCurrentRole() {
    return sessionStorage.getItem('current-role');
  }
  getCurrentRoleID() {
    
    return sessionStorage.getItem('current-role-Id');
  }
  getCurrentRoleName() {
    return sessionStorage.getItem('current-role-name');
  }

  getCurrentDashboardName() {
    return sessionStorage.getItem('current-role-dashboard');
  }

  TokenExpire = function () {
    sessionStorage.clear();
    window.location.href = "https://sso.rajasthan.gov.in/signin";
  }

  //for PRODUCTION =====================================================================================

   getAPI_URL(): string 
   {
     var path = window.location.pathname.toString();
     var hrefs = window.location.href.toString();
    // console.log(hrefs);
     var URL = 'https://apisipfportal.rajasthan.gov.in';//hrefs.replace(path,"");
  // var URL = 'http://172.21.91.160/sipfweb';
    // var URL  = 'http://10.70.230.157:8090/api/'
      URL = URL + '/api/';
     return URL;
   }



 //for Local ================================================================== 


//   getAPI_URL(): string 
//   {
//   var path = window.location.pathname.toString();
//     var hrefs = window.location.href.toString();
//    var URL = hrefs.replace(path, "");
//  // var URL ="http://10.70.230.157"
//      if (URL == "http://localhost:4200") 
//     {
//        //  URL =   'http://172.22.32.169/api/';     

//     URL = 'http://localhost:32229/api/';
//    //URL = 'http://10.68.243.24/SIPF_APP/api/';

//      }
//    else {
//       URL = URL + ':8090/api/';
//     }
//   return URL;
// }



  //=============================================================================  

  getSSO_URL(): string {
    var path = window.location.pathname.toString();
    var hrefs = window.location.href.toString();
    var URL = hrefs.replace(path, "");
    if (URL == "http://localhost:4200") {
      URL = 'https://sso.rajasthan.gov.in/sso';
    }
    else {
      URL = 'https://sso.rajasthan.gov.in/';
    }

    return URL;
  
  }

  //=============================================================================

  getFile_URL(): string {

    var path = window.location.pathname.toString();
    var hrefs = window.location.href.toString();
    var URL = hrefs.replace(path, "");
    // if( URL == "http://localhost:4200")
    // {
    //     URL =   'https://ssotest.rajasthan.gov.in/sso';
    // }
    // else
    // {
    //     URL =    'https://ssotest.rajasthan.gov.in/sso';
    // }
    return URL;
  }
  postRequest(data: any, ACTION: string, KEY: string): Observable<ClsResponse> {

    var headerss = new HttpHeaders();
    //   this.createAuthorizationHeader(headerss);
    //  headerss.append('token', this.getToken());
    //  headerss.append('emp', this.getEMPID());
    //  headerss.append('Content-Type', 'application/json');

    headerss = headerss.set('token', this.getToken()).set('emp', this.getEMPID());
    // let headerss = new HttpHeaders().set('token', this.getToken()); // create header object
    //headerss = headerss.append('emp',  this.getEMPID()); // add a new header, creating a new object
    //headerss = headerss.append('Content-Type',  'application/json'); // add another header


    // headers: {'token':this.getToken(),'emp':this.getEMPID(),'Content-Type':'application/json'} 
    // New version. Now convert other and fix other issues.

    ACTION = this.getAPI_URL() + ACTION;
    return this.http.post(ACTION, data, { headers: headerss }).
      pipe(
        map((resp: ClsResponse) => {

          return resp;
        }), catchError(this.handleErrors)
      )
  }


  postRequestLogin(data: any, ACTION: string, KEY: string): Observable<ClsResponse> {

    var headerss = new HttpHeaders();
    ACTION = this.getAPI_URL() + ACTION;
    return this.http.post(ACTION, data, { headers: headerss }).
      pipe(
        map((resp: ClsResponse) => {

          return resp;
        }), catchError(this.handleErrors)
      )
  }



  //SIPF_STRING.API_URL
  // return this.http.post(this.getAPI_URL() + ACTION, body, { headers: headers,observe: 'response' })
  //   .map((res: HttpResponse) => res.json())
  //   .catch(this.handleError);

  getRequest(data: any, ACTION: string, KEY: string) {

    var headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    //var body = JSON.stringify(data);

    // Generate Paramenter from Object
    let params = new URLSearchParams();
    params.set('token', this.getToken())
    for (let key in data) {
      params.set(key, data[key])
    }
    // console.log(params);
    //SIPF_STRING.API_URL
    window.open(this.getAPI_URL() + ACTION + "?" + params.toString(), "_blank");

  }

  public handleErrors(error: HttpErrorResponse) 
  {
    $.AdminOreo.loader.unblockUI();
    if (error.status === 400 && error.statusText == 'Bad Request') {
      $.AdminOreo.loader.unblockUI();
      $.AdminOreo.alert.message_info("", "Session Expire or Some Other User Login With Same Credentials.", function () {
        sessionStorage.clear();
        window.location.replace("https://sso.rajasthan.gov.in/sso");
      });
    }
    else if (error.status === 404) {
      $.AdminOreo.loader.unblockUI();
      //$.AdminOreo.alert.message_info("", "The server is down, Contact to Administrator ", function () {  });
    }
    else {
      $.AdminOreo.loader.unblockUI();
     // $.AdminOreo.alert.message_error("!Error", "Server error");

     $.AdminOreo.alert.message_info("Response Awaited", "Please Try Again!");
    }
   // return Observable.throwError('Server error');

   return Observable.throwError('Please Try Again!');
  }


  public handleError(error: Response) {
    $.AdminOreo.loader.unblockUI();
    if (error.status === 400 && error.statusText == 'Bad Request') {
      $.AdminOreo.loader.unblockUI();
      $.AdminOreo.alert.message_info("", "Session Expire Login Again.", function () {
        sessionStorage.clear();
        $(location.href = '/authentication/sign-in');
      });
    }
    else {
      $.AdminOreo.loader.unblockUI();
      $.AdminOreo.alert.message_info("Response Awaited", "Please Try Again!");
     // $.AdminOreo.alert.message_error("!Error", "Server error");
      //alert('Server Error');
      // console.log(error.json())
    }
    //return 'Server error'
    return 'Please Try Again!'
    //return Observable.throwError( 'Server error');
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  formattedDate(d: any) {

    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day}/${month}/${year}`;
  }



  // encryptData(data) {

  //   return CryptoJS.AES.encrypt(data, this.encryptSecretKey).toString();

  //   }

  //   decryptData(data) {

  //     CryptoJS.AES.decrypt(data, this.encryptSecretKey).toString(CryptoJS.enc.Utf8);

  //   }

  encryptData(value) {

    var key = CryptoJS.enc.Utf8.parse(this.encryptSecretKey);
    var iv = CryptoJS.enc.Utf8.parse(this.encryptSecretKey);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });


    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
    encrypted = encrypted.toString().replace("/", "#");
 return btoa(encrypted);
 //  return encrypted;
  }
 


  //The get method is use for decrypt the value.
  decryptData(value) {
    
    if (value === undefined || value === null) {
      return value;
    }
    else {
     value = atob(value);
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");
      value = value.replace("#", "/");


      var key = CryptoJS.enc.Utf8.parse(this.encryptSecretKey);
      var iv = CryptoJS.enc.Utf8.parse(this.encryptSecretKey);
      var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    }

  }

  //   exporttoexcel(e,filename){
  //   let element = document.getElementById(e);
  //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   xlsx.writeFile(wb, filename+'.xlsx');
  // }
  exporttoexcel(e, filename) {
    let element = document.getElementById(e);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'mm/dd/yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, filename + '.xlsx');
  }

  exportJsonToExcel(e, filename, data) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);//converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}_export_${new Date().getTime()}.xlsx`);
  }

  onPreviewClick(e) {
    // let printContents = document.getElementById(e).innerHTML;
    //  var originalContents = document.body.innerHTML;
    //  document.body.innerHTML = printContents;
    //  window.print();
    //  document.body.innerHTML = originalContents;
    // window.location.reload();

    let divToPrint = document.getElementById(e).innerHTML;
    let newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    newWindow.document.open();
    newWindow.document.write(`
  <html>
      <head>
        <title>__ SIPF Rajasthan __</title>
        <style>
        a {
          color: #f96332;
          text-decoration: none;
      }
     table tr th {
        background-color: #60bafd;
        font-size: 14px;
        color: #000;
        padding: 7px 12px 7px 12px;
        border: 1px solid #eceeef;
        
    }
    table tr td {
      padding: 0.25rem;
      vertical-align: top;
      border: 1px solid #eceeef;
      font-size: 14px;
      padding-right: 5px;
      padding-left: 5px;
      font-weight: 500;
      color: #000;
      border-collapse: collapse;
  }
  table {
    border-collapse: collapse;
    width:100%;
  }
        </style>
      </head>
      <body onload="window.print();window.close()">${divToPrint}
      </body>
    </html>
  `);
    newWindow.document.close();
  }

  schemeNameIdMapping(schemeId) {
      let mappingData = {
          "30797000005009" : "GPF",
          "30799000004621" : "GPF SAB",
          "39372000005714" : "GPF-2004",
          "30800000004255" : "NPS",
          "30798000005207" : "SI"
      }
      
      return mappingData[schemeId]
  } 

  // session token generate start

  ngOnDestroy() {
    this.clearTimeout();
    this.clearTimer();
  }

  SetlogoutInerval() {
    let seconds: number = 600;
    let statSec: number = 600;
    this.interval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;
      this.chkToken_sing = seconds.toString();
      if (seconds == 0) {
        this.chkToken_sing = "Finished";
        clearInterval(this.interval);
      }
    }, 1000);
  }

  resetTimeout() {
    this.clearTimeout();
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  // tokenchkoninitFunction() {
  //   this.postRequest(null, 'WebNewAPI/IsTokenactive?tokens=' + sessionStorage.getItem("UATTOKEN"), 'list').subscribe(
  //     x => {
  //       $.AdminOreo.loader.unblockUI();
  //       if (x.success) {
  //         if (x.data == null || x.data == "") {
  //           $.AdminOreo.alert.message_info("", "Session Expire or Some Other User Login With Same Credentials.", function () {
  //             sessionStorage.clear();
  //             $.ajax({
  //               type: 'POST',
  //               url: 'https://sso.rajasthan.gov.in/signout',
  //               data: {
  //                 'userdetails': sessionStorage.getItem("SSO_token")
  //               },
  //               success: function (msg) {
  //                 alert(msg);
  //               },
  //               complete: function () {
  //                 window.location.href = 'https://sso.rajasthan.gov.in/signout';
  //               }
  //             });
  //           });
  //         }
  //         else {
  //           sessionStorage.setItem("UATTOKEN", x.data);
  //           this.chkToken_sing = "0";
  //           clearInterval(this.interval);
  //           this.SetlogoutInerval();
  //         }
  //       } else {
  //         $.AdminOreo.alert.message_info("", "Session Expire or Some Other User Login With Same Credentials.", function () {
  //           sessionStorage.clear();
  //           $.ajax({
  //             type: 'POST',
  //             url: 'https://sso.rajasthan.gov.in/signout',
  //             data: {
  //               'userdetails': sessionStorage.getItem("SSO_token")
  //             },
  //             success: function (msg) {
  //               alert(msg);
  //             },
  //             complete: function () {
  //               window.location.href = 'https://sso.rajasthan.gov.in/signout';
  //             }
  //           });
  //         });
  //       }
  //     },
  //     err => {
  //       $.AdminOreo.loader.unblockUI();
  //       $.AdminOreo.alert.message_error(err);
  //       $.ajax({
  //         type: 'POST',
  //         url: 'https://sso.rajasthan.gov.in/signout',
  //         data: {
  //           'userdetails': sessionStorage.getItem("SSO_token")
  //         },
  //         success: function (msg) {
  //           alert(msg);
  //         },
  //         complete: function () {
  //           window.location.href = 'https://sso.rajasthan.gov.in/signout';
  //         }
  //       });
  //     }
  //   );
  // }


  //newcode 29aug2024 by avinesh
  tokenchkoninitFunction() 
  {
    this.postRequest(null, 'WebNewAPI/IsTokenactive?tokens=' + sessionStorage.getItem("UATTOKEN") + '&authSSO=' + sessionStorage.getItem("token")+ '&ID=' + sessionStorage.getItem("employee_id"), 'list').subscribe(
      x => {
        $.AdminOreo.loader.unblockUI();
        if (x.success) {
          if (x.data == null || x.data == "") {
            $.AdminOreo.alert.message_info("", "Session Expire or Some Other User Login With Same Credentials.", function () {
              sessionStorage.clear();
              $.ajax({
                type: 'POST',
                url: 'https://sso.rajasthan.gov.in/signout',
                data: {
                  'userdetails': sessionStorage.getItem("SSO_token")
                },
                success: function (msg) {
                  alert(msg);
                },
                complete: function () {
                  window.location.href = 'https://sso.rajasthan.gov.in/signout';
                }
              });
            });
          }
          else {
            sessionStorage.setItem("UATTOKEN", x.data);
            this.chkToken_sing = "0";
            clearInterval(this.interval);
            this.SetlogoutInerval();
          }
        } else {
          $.AdminOreo.alert.message_info("", "Session Expire or Some Other User Login With Same Credentials.", function () {
            sessionStorage.clear();
            $.ajax({
              type: 'POST',
              url: 'https://sso.rajasthan.gov.in/signout',
              data: {
                'userdetails': sessionStorage.getItem("SSO_token")
              },
              success: function (msg) {
                alert(msg);
              },
              complete: function () {
                window.location.href = 'https://sso.rajasthan.gov.in/signout';
              }
            });
          });
        }
      },
      err => {
        $.AdminOreo.loader.unblockUI();
        $.AdminOreo.alert.message_error(err);
        $.ajax({
          type: 'POST',
          url: 'https://sso.rajasthan.gov.in/signout',
          data: {
            'userdetails': sessionStorage.getItem("SSO_token")
          },
          success: function (msg) {
            alert(msg);
          },
          complete: function () {
            window.location.href = 'https://sso.rajasthan.gov.in/signout';
          }
        });
      }
    );
  }


  myTimeoutFunction() {
    this.postRequest(null, 'WebNewAPI/Tokenverify?tokens=' + sessionStorage.getItem("UATTOKEN"), 'list').subscribe(
      x => {
        $.AdminOreo.loader.unblockUI();
        if (x.success) {
          if (x.data == null || x.data == "") 
          {
            $.AdminOreo.alert.message_info("", "Session Expire or Some Other User Login With Same Credentials.", function () {
              sessionStorage.clear();
              $.ajax({
                type: 'POST',
                url: 'https://sso.rajasthan.gov.in/signout',
                data: {
                  'userdetails': sessionStorage.getItem("SSO_token")
                },
                success: function (msg) {
                  alert(msg);
                },
                complete: function () {
                  window.location.href = 'https://sso.rajasthan.gov.in/signout';
                }
              });
            });
          }
          else {
            sessionStorage.setItem("UATTOKEN", x.data);
            this.chkToken_sing = "0";
            clearInterval(this.interval);
            this.SetlogoutInerval();
          }
        } else {
          $.AdminOreo.alert.message_info("", "Session Expire or Some Other User Login With Same Credentials.", function () {
            sessionStorage.clear();
            $.ajax({
              type: 'POST',
              url: 'https://sso.rajasthan.gov.in/signout',
              data: {
                'userdetails': sessionStorage.getItem("SSO_token")
              },
              success: function (msg) {
                alert(msg);
              },
              complete: function () {
                window.location.href = 'https://sso.rajasthan.gov.in/signout';
              }
            });
          });
        }
      },
      err => {
        $.AdminOreo.loader.unblockUI();
        $.AdminOreo.alert.message_error(err);
        $.ajax({
          type: 'POST',
          url: 'https://sso.rajasthan.gov.in/signout',
          data: {
            'userdetails': sessionStorage.getItem("SSO_token")
          },
          success: function (msg) {
            alert(msg);
          },
          complete: function () {
            window.location.href = 'https://sso.rajasthan.gov.in/signout';
          }
        });
      }
    );
  }

  redirectToSignOut() {
    $.ajax({
      type: 'POST',
      url: 'https://sso.rajasthan.gov.in/signout',
      data: {
        'userdetails': sessionStorage.getItem("SSO_token")
      },
      success: function (msg) {
        alert(msg);
      },
      complete: function () {
        window.location.href = 'https://sso.rajasthan.gov.in/signout';
      }
    });
  }

  clearTimer() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}
